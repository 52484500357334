import React, {useEffect, useState} from "react";
import {Button} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import { getEntitlementsByWalletAddress,getCurrentWalletConnected } from '../../utils/interact'
import { setCoordinates,setSceneIds } from "../../store/entitlementsState";
import {setEntitlementsToRemove} from "../../store/selectedContributer";

export default function Entitlements(){

    const { coordinates, sceneIds } = useSelector((state) => state.entitlements);
    const { walletAddress } = useSelector((state) => state.adminUser)
    const dispatch = useDispatch();

    useEffect( async () => {
        const wallet = await getCurrentWalletConnected()
        getEntitlementsByWalletAddress(wallet.address).then(result => {
            console.log(result);
            let coords = pairStrings(result[1])
            dispatch(setCoordinates(coords));

            dispatch(setSceneIds(result[0]));
        })
    },[])

    function pairStrings(inputArray) {
        const pairedArray = [];
        for (let i = 0; i < inputArray.length - 1; i += 2) {
            pairedArray.push(inputArray[i] + ', ' + inputArray[i + 1]);
        }
        return pairedArray;
    }

    return(
        <div className="ui container">
            <div className="ListingsTableContainer_listingsTableContainer__h1r2j ">
                <div className="tableContainer">
                    <div className="TableContent">
                        <table className="ui very basic table">

                            <tbody>
                            <tr>
                                {/*<th></th>*/}
                                <th>SceneId</th>
                                <th>Coordinates</th>
                            </tr>

                            {
                                sceneIds.map((item, index) => {
                                    return (
                                        <tr key={`Contributor_${index}`}>
                                            {/*<td>*/}
                                            {/*    <input type="checkbox"/>*/}
                                            {/*</td>*/}
                                            <td>
                                                {item}
                                            </td>
                                            <td>
                                                {coordinates[index]}
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*<Button*/}
                {/*    className="ui small primary button"*/}
                {/*>*/}
                {/*    Set Operator*/}
                {/*</Button>*/}
                {/*<Button*/}
                {/*    className="ui small primary button"*/}
                {/*>Claim</Button>*/}
            </div>
        </div>
    )
}