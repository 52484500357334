import { createSlice} from "@reduxjs/toolkit";

export const entitlementsSlice = createSlice({
    name: "entitlements",
    initialState: {
       coordinates: [],
        sceneIds: []
    },
    reducers: {
        setCoordinates: (state, action)=> {
            state.coordinates = action.payload
        },
        setSceneIds: (state, action) => {
            state.sceneIds = action.payload
        }
    }
});

export const { setCoordinates,setSceneIds } = entitlementsSlice.actions;

export default entitlementsSlice.reducer;